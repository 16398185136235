import React from 'react';

interface ProductivityIndicatorProps {
    productivity: number;
}

const ProductivityIndicator: React.FC<ProductivityIndicatorProps> = ({productivity}) => {
    if (productivity <= 0) {
        return null;
    }

    let indicatorText = '';
    let color = '';

    if (productivity >= 95) {
        indicatorText = 'Stark unterbesetzt';
        color = 'red';
    } else if (productivity >= 90 && productivity < 95) {
        indicatorText = 'Leicht unterbesetzt';
        color = 'orange';
    } else if (productivity >= 80 && productivity < 90) {
        indicatorText = 'Ideal';
        color = 'green';
    } else if (productivity >= 75 && productivity < 80) {
        indicatorText = 'Leicht überbesetzt';
        color = 'orange';
    } else if (productivity < 75) {
        indicatorText = 'Überbesetzt';
        color = 'red';
    } else {
        return null;
    }

    return (
        <span style={{
            color: color,
            fontSize: '1em'
        }}>
            {indicatorText}
        </span>
    );
}

export default ProductivityIndicator;

