import React from 'react';

interface LabeledInputFormProps {
    label?: string;
    type?: string;
    name: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onDoubleClick?: (e: React.MouseEvent<HTMLInputElement>) => void; // Corrected type
}

const LabeledInputForm: React.FC<LabeledInputFormProps> = ({
                                                               label = "",
                                                               type = "text",
                                                               name = "",
                                                               value,
                                                               onChange = () => {
                                                               },
                                                               onDoubleClick = () => {
                                                               },
                                                           }) => {
    const numberRegex = /^-?\d*(\.\d+)?$/;
    const isValidNumber = value !== undefined && numberRegex.test(value);
    return (
        <label>
            {label} {!isValidNumber && value !== undefined && (
            <span style={{color: 'red', fontSize:"0.8em"}}><br/>(Ungültige Eingabe - Hinweis: Für Komma bitte Punkt verwenden)</span>
        )}
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                onClick={onDoubleClick}
            />
        </label>
    );
};

export default LabeledInputForm;
