import React from 'react';
import Calculator from "./components/calculator/Calculator";
import './components/calculator/Calculator.css';

function App() {
  return (
    <div className="App">
      <h1 className={"title"}>Franchise Tools</h1>
      <Calculator></Calculator>
    </div>
  );
}

export default App;
