import React, {useEffect, useState} from 'react';
import LabeledInputForm from '../LabeledInputForm';
import './Calculator.css';
import ProductivityIndicator from "../ProductivityIndicator";
import CustomerProductivityIndicator from "../CustomerProductivityIndicator";

export default function Calculator() {
    const roundToDecimalPlace = (num: number, decimalPlaces: number): number => {
        const factor = Math.pow(10, decimalPlaces);
        return Math.round(num * factor) / factor;
    };

    const grossHourlyWageInit: number = roundToDecimalPlace(1945 / 173.2, 4);
    const [isCopySupported, setIsCopySupported] = useState(false);

    useEffect(() => {
        // Check if Clipboard API is supported
        setIsCopySupported(!!navigator.clipboard);
    }, []);

    function copyClipBoard() {
        let text = `\nBruttostundenlohn: ${calcState.grossHourlyWage} €\n`;
        text += `Nettoumsatz: ${calcState.netRevenue.toFixed(2)} €\n`;
        text += `Gesamtstunden: ${calcState.totalHours.toFixed(2)}\n`;
        text += `Management Stundenabzug: ${calcState.managementHoursDeduction.toFixed(2)}\n`;
        text += `Gästeanzahl: ${calcState.customerCount.toFixed(2)}\n`;
        text += `Produktivität: ${calcState.resultProductivity.toFixed(2)} €\n`;
        text += `Gästeproduktivität: ${calcState.resultCustomerProductivity.toFixed(2)} €\n`;
        text += `Crew Labour: ${calcState.resultCrewLabour.toFixed(2)} %\n`;
        text += `Labour Inklusive Management: ${calcState.resultLabourInclManagement.toFixed(2)} %\n`;

        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Erfolgreich in die Zwischenablage kopiert:' + text);
            })
            .catch(err => {
                console.error('', err);
            });
    }

    const [calcState, setCalcState] = useState({
        inputNetRevenue: '0',
        inputTotalHours: '0',
        inputGrossHourlyWage: grossHourlyWageInit.toString(),
        inputManagementDeduction: '0',
        inputCustomerCount: '0',
        // Bruttostundenlohn
        grossHourlyWage: grossHourlyWageInit,
        // Nettoumsatz
        netRevenue: 0,
        // Gesamtstunden
        totalHours: 0,
        // Management Stundenabzug
        managementHoursDeduction: 0,
        //Gästeanzahl
        customerCount: 0,
        // Results
        resultProductivity: 0,
        resultCustomerProductivity: 0,
        resultCrewLabour: 0,
        resultLabourInclManagement: 0,
    });

    function handleOnDoubleClick(e: any) {
        e.currentTarget.value = '';
    }

    function calculateProductivity(netRevenue: number, totalHours: number) {
        return totalHours !== 0 ? netRevenue / totalHours : 0;
    }

    function calculateCrewLabour(totalHours: number, grossHourlyWage: number, netRevenue: number) {
        return netRevenue !== 0 ? (totalHours * grossHourlyWage) / netRevenue * 100 : 0;
    }

    function calculateLabourInclManagement(totalHours: number, managementHoursDeduction: number, grossHourlyWage: number, netRevenue: number) {
        return netRevenue !== 0 ? ((totalHours + managementHoursDeduction) * grossHourlyWage) / netRevenue * 100 : 0;
    }

    function calculateCustomerProductivity(customerCount: number, totalHours: number) {
        return customerCount !== 0 ? (customerCount / totalHours) : 0;
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const {name, value} = e.target;

        // Update string inputs
        const updatedCalcState = {
            ...calcState,
            [name]: value,
        };

        // Parse input values for calculations
        const netRevenue = parseFloat(updatedCalcState.inputNetRevenue) || 0;
        const totalHours = parseFloat(updatedCalcState.inputTotalHours) || 0;
        const grossHourlyWage = parseFloat(updatedCalcState.inputGrossHourlyWage) || grossHourlyWageInit;
        const managementHoursDeduction = parseFloat(updatedCalcState.inputManagementDeduction) || 0;
        const customerCount = parseFloat(updatedCalcState.inputCustomerCount) || 0;

        // Calculate results
        const resultProductivity = roundToDecimalPlace(calculateProductivity(netRevenue, totalHours), 2);
        const resultCrewLabour = roundToDecimalPlace(calculateCrewLabour(totalHours, grossHourlyWage, netRevenue), 2);
        const resultLabourInclManagement = roundToDecimalPlace(calculateLabourInclManagement(totalHours, managementHoursDeduction, grossHourlyWage, netRevenue), 2);
        const resultCustomerProductivity = roundToDecimalPlace(calculateCustomerProductivity(customerCount, totalHours), 2);

        // Update state with parsed values and results
        setCalcState({
            ...updatedCalcState,
            netRevenue,
            totalHours,
            grossHourlyWage,
            managementHoursDeduction,
            customerCount,
            resultProductivity,
            resultCustomerProductivity,
            resultCrewLabour,
            resultLabourInclManagement,
        });
    }

    return (
        <>
            <LabeledInputForm
                label="Bruttostundenlohn (€)"
                name="inputGrossHourlyWage"
                value={calcState.inputGrossHourlyWage}
                onChange={handleChange}
            />

            <LabeledInputForm
                label="Nettoumsatz (€)"
                name="inputNetRevenue"
                value={calcState.inputNetRevenue}
                onChange={handleChange}
                onDoubleClick={handleOnDoubleClick}
            />

            <br/>
            <LabeledInputForm
                label="Gesamtstunden"
                name="inputTotalHours"
                value={calcState.inputTotalHours}
                onChange={handleChange}
                onDoubleClick={handleOnDoubleClick}
            />

            <br/>
            <LabeledInputForm
                label="Management Stundenabzug"
                name="inputManagementDeduction"
                value={calcState.inputManagementDeduction}
                onChange={handleChange}
                onDoubleClick={handleOnDoubleClick}
            />
            <br/>
            <LabeledInputForm
                label="Gästeanzahl"
                name="inputCustomerCount"
                value={calcState.inputCustomerCount}
                onChange={handleChange}
                onDoubleClick={handleOnDoubleClick}
            />

            <section className="results-container">
                <h1 className={"results-container-title"}>Ergebnisse:</h1>
                <dl>
                    <dt>Produktivität:</dt>
                    <dd>{calcState.resultProductivity.toFixed(2)} €</dd>
                    <dd>Bewertung: <ProductivityIndicator
                        productivity={parseFloat(calcState.resultProductivity.toFixed(2))}/></dd>
                    <dt>Gäste Produktivität:</dt>
                    <dd>{calcState.resultCustomerProductivity.toFixed(2)}</dd>
                    <dd>Bewertung: <CustomerProductivityIndicator
                        productivity={parseFloat(calcState.resultCustomerProductivity.toFixed(2))}/></dd>
                    <dt>Crew Labour:</dt>
                    <dd>{calcState.resultCrewLabour.toFixed(2)} %</dd>
                    <dt>Labour inklusive Management:</dt>
                    <dd>{calcState.resultLabourInclManagement.toFixed(2)} %</dd>
                    {isCopySupported ? <button onClick={copyClipBoard}>📑 Kopie</button> : null}
                </dl>
            </section>
            <footer>
                Version: 0.94
            </footer>
        </>

    );
}
