import React from "react";

interface CustomerProductivityProps {
    productivity: number;
}

const CustomerProductivityIndicator: React.FC<CustomerProductivityProps> = ({productivity}) => {
    if (productivity <= 0) {
        return null;
    }
    let indicatorText = "";
    let color = "";

    if (productivity > 11) {
        indicatorText = "Unterbesetzt";
        color = "red";
    } else if (productivity > 9.5) {
        indicatorText = "Leicht unterbesetzt";
        color = "orange";
    } else if (productivity >= 8 && productivity <= 9.5) {
        indicatorText = "Ideal";
        color = "green";
    } else if (productivity >= 7.5 && productivity < 8) {
        indicatorText = "Leicht überbesetzt";
        color = "orange";
    } else if (productivity < 7.5) {
        indicatorText = "Überbesetzt";
        color = "red";
    } else {
        return null;
    }

    return (
        <span style={{
            color: color,
            fontSize: "1em"
        }}>
        {indicatorText}
    </span>
    );
}

export default CustomerProductivityIndicator;
